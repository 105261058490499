/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';

import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

import Overspeed from "./overspeed";
// import StockyardParkingInformation from "./stockyardParkingInformationComponent";
import NightDriving from "./tptnightdrivingComponent";
import HarshBreaking from "./tptharshbreakComponent";
import StoppageReport from "./tptstoppagereportComponent";
import ContinousTravel from "./tptcontinuousComponent";
import FreeWheelingReport from "./freewheelingreport";
import TptTrucksAtDealer from "./trucksatdealer"
import WireDisconnection from "./wireDisconnection"
$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class stockyardManagementSystemTabsComponent extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            active_tab: '1',
            active_tab_bottom_left: '1',
            active_tab_icon: '1',
            active_tab_right_align: '1',
            active_tab_vertical: '1',
            active_tab_pill: '1',
            active_tab_pill_icon: '1',
            active_tab_dark_color: '1',
            active_tab_primary: '1',
            active_tab_secondary: '1',
            isRoleExists: 0,
            adaniCheck: 0,

        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {

        // console.log("tpt_otheroem ", localStorage.getItem("roles"))
        try {
            // console.log(localStorage.getItem("transportercode"), "transporter code")
            if (localStorage.getItem("roles").includes("tpt_otheroem") == true || localStorage.getItem("transportercode").includes("T297") ||
                localStorage.getItem("transportercode") == "T297") {
                this.setState({
                    isRoleExists: 1
                })
                // this.toggle('6')
            }
            else {
                this.setState({
                    isRoleExists: 0
                })
            }
            // console.log("localStorage.getItem(transportercode) ", localStorage.getItem("transportercode"))
            if (localStorage.getItem("transportercode") == "T297" || JSON.parse(localStorage.getItem("transportercode")).indexOf("T297") >= 0
                || localStorage.getItem("transportercode").includes("T297") || localStorage.getItem("transportercode").includes("T305")) {
                this.setState({
                    active_tab: '1'
                })
                // this.toggle('6')
            }
        }
        catch (e) {

        }
        var tptCode = localStorage.getItem('transportercode');
        if (tptCode.includes("T293") == true) {
            var adaniCheck = 1;
        }
        else {
            var adaniCheck = 0;
        }
        this.setState({
            adaniCheck: adaniCheck
        });

    }

    toggle(tab) {
        if (this.state.active_tab !== tab) {
            this.setState({
                active_tab: tab
            });
        }

    }

    render() {

        return (

            <div className="container-fluid">

                <div className="row">
                    <div className="col-xl-12 col-lg-12">

                        <Nav tabs className={'tabs-color'}  style={{marginLeft:"1em"}}>

                            <NavItem >
                                <NavLink
                                    className={classnames({ active: this.state.active_tab === '1' })}
                                    onClick={() => { this.toggle('1'); }} >
                                    Overspeeding Report
                                </NavLink>
                            </NavItem>
                            {/* {(localStorage.getItem("transportercode").includes("T297") || localStorage.getItem("transportercode").includes("T305")
                                || localStorage.getItem("transportercode").includes("T329")) ? */}
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.active_tab === '2' })}
                                        onClick={() => { this.toggle('2'); }} >
                                        NightDriving Report
                                    </NavLink>
                                </NavItem>
                                {/* : ""} */}
                             {
                                (localStorage.getItem("transportercode").indexOf("T305") >= 0 || localStorage.getItem("transportercode").indexOf("T329") >=0 ||localStorage.getItem("transportercode").indexOf("T290") >=0|| localStorage.getItem("transportercode").indexOf("GARL") >=0)?   
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.active_tab === '3' })}
                                            onClick={() => { this.toggle('3'); }} >
                                            HarshBreaking Report
                                        </NavLink>
                                    </NavItem>:""
                            }
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.active_tab === '4' })}
                                            onClick={() => { this.toggle('4'); }} >
                                            Stoppage Report
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.active_tab === '5' })}
                                            onClick={() => { this.toggle('5'); }} >
                                            Continous Travel Report
                                        </NavLink>
                                    </NavItem>
                                    {(localStorage.getItem("transportercode").includes("T305")) ?
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.active_tab === '6' })}
                                            onClick={() => { this.toggle('6'); }} >
                                            Free Wheeling Report
                                        </NavLink>
                                    </NavItem>
                                    : ""}
                                    {(localStorage.getItem("transportercode").includes("GBFC") || localStorage.getItem("transportercode").includes("T268") ) ?
                                    <NavItem>
                                    <NavLink className={classnames({ active: this.state.active_tab === '7' })}
                                        onClick={() => { this.toggle('7'); }} >
                                        Trucks At Dealer
                                    </NavLink>
                                </NavItem>:""
                                    }
                                {(localStorage.getItem("transportercode").includes("T329")) ?
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.active_tab === '8' })}
                                            onClick={() => { this.toggle('8'); }} >
                                            Wire Disconnection
                                        </NavLink>
                                    </NavItem> : ""
                                }
                                    
                                    
                        </Nav>

                        <TabContent activeTab={this.state.active_tab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <Overspeed />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                        <NightDriving />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="3">
                                <Row>
                                    <Col sm="12">
                                        < HarshBreaking/>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="4">
                                <Row>
                                    <Col sm="12">
                                        <StoppageReport />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="5">
                                <Row>
                                    <Col sm="12">
                                        <ContinousTravel />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="6">
                                <Row>
                                    <Col sm="12">
                                        <FreeWheelingReport />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="7">
                                <Row>
                                    <Col sm="12">
                                        <TptTrucksAtDealer />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="8">
                                <Row>
                                    <Col sm="12">
                                        <WireDisconnection />
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>

            </div>

        );
    }
}
