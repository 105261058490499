/* eslint-disable no-redeclare */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Autosuggest from 'react-autosuggest';

import Breadcrumb from '../common/breadcrumb.component';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import $ from "jquery";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import "@grapecity/wijmo.styles/wijmo.css";
import * as pdf from "@grapecity/wijmo.pdf";
import * as wijmo from "@grapecity/wijmo";
import DrawMap from '../common/drawmap';
import NextBillionMaps from '../common/nbmaps';
import Trucklocaction from '../layouts/trucklocaction';
import Constant from "../common/googlemapkey";
var infoBox = require('../common/google-infowindow');
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)
const ResponsiveReactGridLayout = WidthProvider(Responsive);
var map;
var markersArray;
var viewMarkersArr = [];
export default class TptTrucksGPSData extends Component {
    constructor(props) {
        super(props);
        this.colWidth = 150;
        this.rowHeight = 40;
        this.state =
        {
            pageTitle: "TPT Truck GPS Data",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            routemap: '',
            allmarkers: '',
            trucks: [],
            truck_no: '',
            selected_truck_no: "",
            startDate: '',
            endDate: '',
            mapinfo: '',
            mapinfoclick: '',
            modules: AllModules,
            defTransitCoords: '',
            activeMarker: {},
            selectedPlace: {},
            dealerCode: '',
            timelinesmarkers: [],
            viewtimelinemarkers: true,
            viewgoogleroutes: true,
            googleroutepath: [],
            loadshow: 'show-n',
            open: false,
            alert: null,
            show: false,
            basicTitle: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true
            },
            gridshow: 'show-m',
            filteredData: [],
            mapshow: 'show-m',
            actgridbtn: "btn-success",
            actmapbtn: "btn-default",
            rowData: null,
            showTrucksList: "show-n",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                trucklocaction: Trucklocaction
            },
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            truckNoList: [],
            overly: "show-n",
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {
                "highlitRow": "data.transit_delay == 1",
                "green": "data.status >= 3"
            },
            tolls: [],
            frequency: "",
            showmarkers: 0,
            tottalDistance: [],
            totaldistanceformap: [],
            tpttrucks: [],
            transporterTrucks: [],
            adaniCheck: 0,
            searchtruckno: "",
            sliderTranslate:"",
            fnrRowData:[],
            usergridstate:[],
            for_google_maps : false,
            shownbmaps:true,
            view_carrier_center :"",
        };
        this.setTruckno = this.setTruckno.bind(this);
        this.onClickShowTruckLocation = this.onClickShowTruckLocation.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        this.getUserGridState()
        var endDate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
        var  startDate= moment.parseZone().subtract(5, 'days').format('YYYY-MM-DD'+" "+'00:00:00');
        var tptCode = localStorage.getItem('transportercode');
        // console.log("tptCode" ,tptCode);
        if (tptCode.includes("T293") == true) {
            var adaniCheck = 1;
        }
        else {
            var adaniCheck = 0;
        }
        this.setState({
            adaniCheck: adaniCheck,
            endDate :endDate,
            startDate :startDate
        });
        var vehicle_group = localStorage.getItem("vehicle_group");
        // console.log("vehicle_group ", vehicle_group)
        var myroles = localStorage.getItem("roles");
        var tptrucks = []
        if (vehicle_group != "") {
            var tparms = {
                transporter_code: tptCode,
                region: vehicle_group
            }
            redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
                .then((response) => {
                    var records = response.data.records;
                    // console.log("tpt records ", records)
                    this.setState({
                        tpttrucks: records
                    })
                })
        }
        var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        if (is_enmovil_transporter == 1) {
            // console.log("Box ", localStorage.getItem('token'));
            this.logPageView(); // Log page view to GA
            if (myroles.indexOf("tpt_vrc") >= 0) {
                this.setState({
                    truckNoList: ["353549091894401"]
                })
            }
            else if(myroles.indexOf("JWS") >= 0) {
                this.setState({
                    truckNoList: ["35259 911234567890"]
                })
            } 
            else {
                var params = {
                    transporter_code: localStorage.getItem("transportercode")
                }
                var deviceparams = {
                    transporter_code: localStorage.getItem("transportercode")
                }
                redirectURL.post("/dashboard/devices", deviceparams).then((response1) => {
                    var transporterTrucks = [];
                    var tptrucks = [];
                    var tptCode = JSON.parse(localStorage.getItem("transportercode"));
                    console.log(response1.data.devices,"tptrucksdevicewa")
                    response1.data.devices.map(function (e) {
                        if (tptCode.indexOf(e.transporter_code) > -1) {
                            console.log(localStorage.getItem('transportercode'),localStorage.getItem('transportercode').includes("T293"),"transporter")
                            if(localStorage.getItem('transportercode').includes("T293") == false)
                            {
                                transporterTrucks.push(e)
                                tptrucks.push(e.truck_no)
                            }
                            else
                            {
                                if(e.state == localStorage.getItem("plant_location"))
                                {
                                    transporterTrucks.push(e)
                                    tptrucks.push(e.truck_no)
                                }
                            }
                           
                        }
                    })
                    console.log(tptrucks,"tptrucksdata")
                    console.log(transporterTrucks,"transporterTrucks")
                    // if(localStorage.getItem('transportercode').includes("T293") && localStorage.getItem("plant_location") !="" &&
                    // localStorage.getItem("plant_location") != undefined)
                    // {
                    //     markers = markers.filter((item)=>{
                    //         console.log(item,"item state")
                            
                    //     })
                    // }
                    // console.log("transporterTrucks ", transporterTrucks)
                    this.setState({
                        truckNoList: tptrucks,
                        transporterTrucks: transporterTrucks
                    })
                });
            }
        }
        else {
            window.location.href = "/";
        }
    }
    getUserGridState(){
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname , screentitle:this.state.pageTitle})
          .then( async (resp) => {
              // console.log("resp ", resp)
               this.setState({
                  usergridstate:resp.data
              });
          })
      }
    selectOptionsItems() {
        let items = [];
        //console.log(this.state.trucks);
        this.state.trucks.forEach(item => {
            if (item != '' && item != null) {
                items.push(<option key={item.truck_no} value={item.truck_no}>{item.truck_no}</option>);
            }
        });
        return items;
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds()); 
        
        if(d != "Invalid Date"){
            this.setState({
                startDate: getHyphenYYYYMMDDHHMMSS(d)
            });
        }
       
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());
        if(d != "Invalid Date"){
        this.setState({
            endDate: getHyphenYYYYMMDDHHMMSS(d)
        });
    }
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    initalList = (event) => {
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        var tptCode = localStorage.getItem("transportercode")
        if (tptCode.includes("_RAKE")) {
            tptunqrecords = dataset
        }else {
            if (tpttrucks.length > 0) {
                dataset.filter(f =>
                    tpttrucks.filter((d) => {
                        if (d.truck_no == f) {
                            tptunqrecords.push(f)
                        }
                    })
                );
            }
            else {
                tptunqrecords = dataset
            }
        }
        
        tptunqrecords = tptunqrecords.slice(0, 100);
        this.setState({
            filteredData: tptunqrecords,
            showTrucksList: "show-m",
        })
    }
    handlerForm = (event) => {
        //console.log("Select", event.target.value);
        // let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        if (tpttrucks.length > 0) {
            dataset.filter(f =>
                tpttrucks.filter((d) => {
                    if (d.truck_no == f) {
                        tptunqrecords.push(f)
                    }
                })
            );
        }
        else {
            tptunqrecords = dataset
        }
        var filteredResult = tptunqrecords.filter(function (e) {
            if (e != "" && e != null) {
                return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }
        });
        // console.log("filteredResult ", filteredResult)
        filteredResult = filteredResult.slice(0, 100);
        this.setState({
            filteredData: filteredResult,
            showTrucksList: "show-m",
        })
    }
    onClickTruck(event) {
        $("#inputTruck").val($(this).text());
    }
    formHandler = async (event) => {
        await this.setState({
            loadshow: 'show-m',
            shownbmaps:false,
        })
        if(!this.state.for_google_maps ||this.state.done_with_google_maps){
            event.preventDefault();
            this.setState({
                for_google_maps:false
            })
        }
        var inputTruck = $("#inputTruck").val().toUpperCase();
        var dataset = this.state.transporterTrucks;
        var tpttrucks = this.state.tpttrucks;
        // console.log("tpttrucks",tpttrucks)
        // console.log("dataset",dataset)
        var tptunqrecords = []
        if (tpttrucks.length > 0) {
            await dataset.filter(f =>
                tpttrucks.filter((d) => {
                    if (d.truck_no == f.truck_no) {
                        tptunqrecords.push(f)
                    }
                })
            );
        }
        else {
            tptunqrecords = dataset
        }
        var truckCheck = 0;
        var deviceid = "";
        if(localStorage.getItem("transportercode").indexOf("JWS01") >= 0 || localStorage.getItem("transportercode").indexOf("Test01") >= 0 )
        {
            truckCheck = 1;
        }
        else{
            if (tptunqrecords.length > 0) {
                for (var d = 0; d < tptunqrecords.length; d++) {
                    // console.log("tpt records ", tptunqrecords[d]);
                    if (tptunqrecords[d].truck_no == inputTruck) {
                        // console.log("tptunqrecords[d] ", tptunqrecords[d])
                        truckCheck = 1;
                        deviceid = tptunqrecords[d].registereddevices
                        //[0].device_id
                        break;
                    }
                }
            }
        }
        // console.log("truckCheck ", truckCheck)
        if (truckCheck == 1) {
            if (googleAnalytics.page.enableGA) {
                let pageTitle = this.state.pageTitle;
                let eventOptions = {
                    "category": pageTitle,
                    "action": this.state.eventFormAction,
                    "label": googleAnalytics.page.action.formSubmittedGenerate,
                }
                googleAnalytics.logEvent(eventOptions);
            }
            //var formdata = new FormData(event.target);
            // console.log($("#inputTruck").val())
            if(this.state.startDate == 'Invalid date'){
                return this.setState({
                    show: true,
                    basicTitle: 'Enter Valid Date',
                    basicType: "danger",
                    loadshow: 'show-n',
                })
            }
            if(this.state.endDate == 'Invalid date'){
                return this.setState({
                    show: true,
                    basicTitle: 'Enter Valid Date',
                    basicType: "danger",
                    loadshow: 'show-n',
                })
            }
            var formdata = {
                truck_no: $("#inputTruck").val().toUpperCase(),
                startDate: (this.state.startDate),
                endDate: (this.state.endDate),
                frequency: this.state.frequency,
                source: "tptlogin",
                transporter_code : JSON.parse(localStorage.getItem("transportercode"))[0],
                device_id: deviceid
            }
            console.log("formdata ", formdata)
            await redirectURL.post("/gmap", formdata, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
                }
            })
                //.then(res => res.json())
                .then(
                    async (result) => {
                        //console.log("result ",result.data);
                        var jsondata = result.data;
                        //  console.log(JSON.parse(jsondata.data))
                        if (result.data.status == 'failure') {
                            this.setState({
                                show: true,
                                basicTitle: 'No GPS data available for this period',
                                basicType: "danger",
                                loadshow: 'show-n',
                                rowData: null,
                                mapinfo: '',
                            })
                        }
                        else {
                            var coordinates = JSON.parse(jsondata.data);
                            //console.log("truckno ", this.props.truckno)
                            console.log("coordinates ", coordinates)
                            var dataarr = []
                            var tottalDistance = [];
                            try {
                                if (coordinates.status == "Failure") {
                                    this.setState({
                                        show: true,
                                        basicTitle: 'No GPS data available for this period',
                                        basicType: "danger",
                                        loadshow: 'show-n',
                                        rowData: null,
                                        mapinfo: '',
                                    })
                                }
                                else {
                                    coordinates.coords.map((item) => {
                                        // console.log("item ", item.cumm_distance)
                                        tottalDistance.push(parseFloat(item.cumm_distance));
                                        // console.log(tottalDistance,"tottalDistance")
                                        dataarr.push({
                                            "truck_no": item.truck_no,
                                            "lat": item.lat,
                                            "lng": item.lng,
                                            "speed": item.speed,
                                            "stime": item.stime,
                                            //"state":item.state,
                                            //"area":item.area,
                                            //"city":item.city,
                                            //"location_type":item.location_type,
                                            "dist_from_prev_point": item.dist_from_prev_point,
                                            "received_on": item.received_on,
                                            "time_from_prev_point": item.time_from_prev_point,
                                            "area": item.area,
                                            "district": item.district,
                                            "state": item.state,
                                            "cumm_distance": item.cumm_distance,
                                            "battery": item.battery,
                                            "near_dealer_name": `${item.near_dealer_code}-${item.near_dealer_name}`,
                                            "near_dealer_distance": item.near_dealer_distance,
                                            "near_dealer_code":item.near_dealer_code,
                                            "geofence_name":item.geofence_name
                                        })
                                    });
                                    // console.log(localStorage.getItem('transportercode'),"plant_location")
									if(localStorage.getItem('transportercode').includes("T293") && localStorage.getItem("plant_location") !="" &&
									localStorage.getItem("plant_location") != undefined)
									{
										dataarr = dataarr.filter((item)=>{
											console.log(item,"item state")
											if(item.state == localStorage.getItem("plant_location"))
											{
												return item;
											}
										})
									}
                                    var totaldistanceData = {}
                                    if(localStorage.getItem("transportercode").indexOf("JWS01") >= 0)
                                    {
                                        var pbrData = false;
                                        totaldistanceData['cumm_distance'] = arrayMax(tottalDistance);
                                    }
                                    else{
                                        var pinnedBottomRowData = {}
                                        pinnedBottomRowData['_id'] = "total";
                                        pinnedBottomRowData['truck_no'] = "";
                                        pinnedBottomRowData['lat'] = "";
                                        pinnedBottomRowData['lng'] = "";
                                        pinnedBottomRowData['speed'] = "";
                                        pinnedBottomRowData['stime'] = "";
                                        pinnedBottomRowData['dist_from_prev_point'] = "";
                                        pinnedBottomRowData['received_on'] = "";
                                        pinnedBottomRowData['time_from_prev_point'] = "Total";
                                        pinnedBottomRowData['area'] = "";
                                        pinnedBottomRowData['district'] = "";
                                        pinnedBottomRowData['state'] = "Total";
                                        pinnedBottomRowData['cumm_distance'] = arrayMax(tottalDistance);
                                        totaldistanceData['cumm_distance'] = arrayMax(tottalDistance);
                                        pinnedBottomRowData['total_travel_time_mins'] = "";
                                        var pbrData = [pinnedBottomRowData]
                                    }
                                    this.setState({
                                        rowData: dataarr,
                                        tottalDistance: pbrData,
                                        totaldistanceformap: [totaldistanceData],
                                        mapinfo: JSON.parse(jsondata.data),
                                        loadshow: 'show-n',
                                        tolls: JSON.parse(jsondata.data).tolls,
                                        inputTruck,
                                        shownbmaps:true,
                                        // for_google_maps:false,   
                                    })
                                    // if(this.state.for_google_maps){
                                        this.renderMap()
                                        // this.setState({
                                        //     done_with_google_maps:true
                                        // })
                                    // }
                                }
                            }
                            catch (e) {
                                this.setState({
                                    show: true,
                                    basicTitle: 'No GPS data available for this period',
                                    basicType: "danger",
                                    loadshow: 'show-n',
                                    rowData: null,
                                    mapinfo: '',
                                })
                            }
                            //console.log("coordinates ", coordinates)
                            //console.log("Modified ", dataarr)
                        }
                    }
                )
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'Enter Valid Truck Number',
                basicType: "danger",
                loadshow: 'show-n',
                rowData: null,
                mapinfo: '',
            })
        }
    }
    onClickFnrDetails = (e) => {
        redirectURL.post("/consignments/getfnrdetails")
        .then(async (response) =>{
            var fnrdetailsdata = response.data
            console.log(fnrdetailsdata,"respData")
            this.setState({
                fnrRowData : fnrdetailsdata,
                sliderTranslate:"slider-translate-60p",
            });
        })
        
    }
    onClickShowGrid() {
        this.setState({
            gridshow: 'show-m',
            mapshow: 'show-n',
            mapinfoclick: "",
            actgridbtn: "btn-success",
            actmapbtn: "btn-default"
        });
    }
    onClickShowMap() {
        this.setState({
            gridshow: 'show-n',
            mapshow: 'show-m',
            mapinfoclick: this.state.mapinfo,
            actgridbtn: "btn-default",
            actmapbtn: "btn-success"
        });
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderTranslate:"",
            // showTrucksList: "show-n",
        });
    }
    hideList() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            // showTrucksList: "show-n",
        });
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    setTruckno = (event) => {
        this.setState({
            selected_truck_no: event.target.text
        })
        // console.log(event.target.text)
    }
    checkHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }
    renderMap = () => {
        loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMap1
    }
    initMap1 = () => {
        //console.log("arr ",arr)
        var currentwindow;
        var dealer = '';
        var tolls = this.state.tolls;
        var arr = [];
        var jsondata = this.state.mapinfo;
        var locations = jsondata.breaks;
        var arr = jsondata.coords;
        var filterDataArr = jsondata.filter_data || []
        if (arr.length > 0 && typeof arr.length != undefined) {
            var lt = arr[0].lat;
            var ln = arr[0].lng;
        }
        else {
            var lt = 28.4519751;
            var ln = 77.0310713;
        }
        var routeinfo;
        try {
            var mapOptions = {
                zoom: 13,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                labels: true,
                mapTypeControlOptions: {
                    mapTypeIds: ['satellite', 'roadmap'],
                },
                center: new window.google.maps.LatLng(lt, ln),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            };
        }
        catch (e) {
            var mapOptions = {
                zoom: 13,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                labels: true,
                mapTypeControlOptions: {
                    mapTypeIds: ['satellite', 'roadmap'],
                },
                center: new window.google.maps.LatLng(28.4519751, 77.0310713),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            };
        }
        try {
            map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
        } catch (e) { }
        var bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(lt, ln));
        //Newly added route 
        var fDArr2 = [];
        var darr2 = [];
        if (localStorage.getItem('email').includes('enmovil.in')) {
            if (filterDataArr.length > 0) {
                darr2 = filterDataArr.filter(function (value, index, arr) {
                    // console.log("Vale ", value)
                    return value
                });
                if (darr2.length < 2000) {
                    var ratio = 1;
                }
                else {
                    var ratio = 20;
                }
                fDArr2 = darr2.filter(function (value, index, darr) {
                    return (index % ratio == 0);
                });
                if (fDArr2.length > 0) {
                    // Filter out valid lat/lng points
                    var validPoints = fDArr2.filter(point =>
                        typeof point.latitude === 'number' && typeof point.longitude === 'number'
                    );
                    // Ensure validPoints is an array and has elements
                    var count_icon = 0 
                    if (validPoints.length > 0) {
                        // Create a path from valid points
                        var filterPath = validPoints.map((point,index) =>
                            new window.google.maps.LatLng(point.latitude, point.longitude)
                        );
                        // Add polyline to the map
                        var filterPolyline = new window.google.maps.Polyline({
                            map: map,
                            path: filterPath,
                            strokeColor: '#eb4034', // Different color for differentiation
                            strokeOpacity: 1.0,
                            strokeWeight: 2.5,
                            icons: [{
                                icon: {
                                    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                    strokeColor: '#FFC43A',
                                    fillColor: '#FFC43A',
                                    fillOpacity: 1,
                                    strokeWeight: 2
                                },
                                repeat: '200px',
                                path: []
                            }]
                        });
                        map.fitBounds(bounds);
                        // console.log("Additional polyline and markers added successfully."); // Debugging
                    } else {
                        // console.error("No valid latitude and longitude data in fDArr2:"); // Debugging
                    }
                } else {
                    // console.log("fDArr2 is empty or not defined."); // Debugging
                }
            }
        }
        // Create our info window content
        var currentinfowindow = null;
        var line = new window.google.maps.Polyline(
            {
                map: map,
                strokeColor: '#157254',
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                icons: [{
                    icon: {
                    		path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    		strokeColor:'#ff8c52',
                    		fillColor:'#ff8c52',
                    		fillOpacity:1,
                    		strokeWeight: 2
                    },
                    repeat: '100px',
                    path: []
                }]
            });
        if (arr.length > 0) {
            var allpoints = [];
            var arr1 = [];
            //console.log("Total ",ratio);
            if (this.state.timelinesmarkers.length > 0) {
                arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
                    return (index % ratio == 0);
                });
                //var arr1 = this.state.timelinesmarkers;
            }
            var arr2 = [];
            var darr = [];
            var tptCode = localStorage.getItem('transportercode') || ''
            if (arr.length > 0) {
                darr = tptCode.includes("4WHEEL_ENTRAC") ? arr : arr.filter(value => value.dist_from_prev_point > 0);
                if (darr.length < 2000) {
                    var ratio = 1;
                }
                else {
                    var ratio = 20;
                }
                //console.log("darr.length ", darr.length)
                arr2 = darr.filter(function (value, index, darr) {
                    return (index % ratio == 0);
                });
                //var arr1 = this.state.timelinesmarkers;
            }
            // view markers code
            // console.log("arr2 here ", arr2)
            if (arr2.length > 0) {
                markersArray = arr2;
                // //console.log("allpoints ", allpoints)
                for (var a = 0; a < arr2.length; a++) {
                    //console.log(arr2[a],"arr2[a]");
                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(arr2[a].lat, arr2[a].lng),
                        icon: {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            strokeColor: '#157254',
                            fillColor: '#157254',
                            fillOpacity: 1,
                            strokeWeight: 5,
                            scale: 1,
                        },
                        map: map,
                        content: arr2[a]
                    });
                    marker.setVisible(false);
                    // 	//console.log("Arr ",arr1[a])
                    window.google.maps.event.addListener(marker, 'mouseover', (function (marker) {
                        return function () {
                            //console.log("Arr ",marker)
                            var contentarr = []
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            var header = "Current Status"
                            contentarr.push({ "key": "Time ", "value": getDDMMYYYYHHMMSS(marker.content.stime) })
                            contentarr.push({ "key": "Speed ", "value": marker.content.speed + " KMPH" })
                            var contentString = infoBox("", header, contentarr, '')
                            infowindow.setContent(contentString);
                            currentwindow = infowindow;
                            infowindow.open(map, marker);
                            //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                        }
                    })(marker));
                    window.google.maps.event.addListener(marker, 'mouseout', function () {
                        infowindow.close();
                    });
                    viewMarkersArr.push(marker);
                }
            }
            var routeinfo = jsondata.route_details;
            var icon_cnt = 0
            for (var i = 0; i < arr2.length - 1; i++) {
                var locationLatLng = [];
                locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
                locationLatLng.push(new window.google.maps.LatLng(arr2[i + 1].lat, arr2[i + 1].lng));
                if (icon_cnt % 25 == 0) {
                    var lineicon = [{
                        icon: {
                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                strokeColor:'#FF0000',
                                fillColor:'#FF0000',
                                fillOpacity:1,
                                strokeWeight: 2
                        },
                        repeat: '100px',
                        path: []
                    }];
                } else { lineicon = [] }
                icon_cnt = icon_cnt + 1
                var consignments_missing_route_line = new window.google.maps.Polyline({
                    map: map,
                    path: locationLatLng,
                    strokeColor: '#157254',
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    icons: lineicon
                });
                // console.log(arr2[i].msgtype)
                if (arr2[i].msgtype == "G") {
                    consignments_missing_route_line.setOptions({
                        strokeColor: "#452a68",
                        strokeWeight: 5.5,
                    })
                }
                bounds.extend(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
            }
            // for (let i = 0; i < arr2.length; i++) {
            //     //if(arr2[i].speed > 0)
            //     //{
            //         var linecolr = "#157254";
            //         if(arr2[i].set  == 2)
            //         {
            //             linecolr = "#0000ff";
            //         }
            //         if(arr2[i].set  == 3)
            //         {
            //             linecolr = "#980000"
            //         }
            //         line.setOptions({strokeColor: linecolr}); 
            //     var path=line.getPath().getArray();
            //     let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
            //     path.push(latLng);
            //     line.setPath(path);
            //     //Change line color based on map type
            //     window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
            //         if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
            //         {
            //             var line=new window.google.maps.Polyline(
            //             {
            //                 map:map,
            //                 strokeColor: '#FFFFFF',
            //                 strokeOpacity: 1.0,
            //                 strokeWeight: 2.5,
            //                 icons: [{
            //                         icon: {
            //                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            //                                 strokeColor:'#ff8c52',
            //                                 fillColor:'#ff8c52',
            //                                 fillOpacity:1,
            //                                 strokeWeight: 2
            //                                 },
            //                         repeat:'100px',
            //                         path:[]
            //                     }]
            //                 });                
            //         }
            //         else
            //         {
            //             var line=new window.google.maps.Polyline(
            //             {
            //                 map:map,
            //                 strokeColor: '#157254',
            //                 strokeOpacity: 1.0,
            //                 strokeWeight: 2.5,
            //                 icons: [{
            //                         icon: {
            //                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            //                                 strokeColor:'#ff8c52',
            //                                 fillColor:'#ff8c52',
            //                                 fillOpacity:1,
            //                                 strokeWeight: 2
            //                                 },
            //                         repeat:'100px',
            //                         path:[]
            //                     }]
            //                 });
            //         }
            //         for (i = 0; i < arr2.length; i++) {
            //                 var path=line.getPath().getArray();
            //                 let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
            //                 path.push(latLng);
            //                 line.setPath(path);
            //                         //map.setCenter(latLng);
            //             }
            //         var x = map.getZoom();
            //         var c = map.getCenter();
            //         window.google.maps.event.trigger(map, 'resize');
            //         map.setZoom(x);
            //         map.setCenter(c);
            //     } );
            //     bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
            //     //}
            // }
            var infowindow = new window.google.maps.InfoWindow();
            var marker, l;
            for (l = 0; l < locations.length; l++) {
                marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
                    icon: require('../../assets/icons/cf.png'),
                    map: map,
                });
                window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
                    return function () {
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";
                        var contentarr = []
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                        var header = "Break";
                        try {
                            if (typeof this.props.mapFor != 'undefined') {
                                var timeinsecs = moment.parseZone(locations[l].break_start).format("x") - moment.parseZone(locations[l].break_start).format("x")
                                contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
                                contentarr.push({ "key": "Break End", "value": locations[l].break_end })
                                contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
                                contentarr.push({ "key": "Address", "value": locations[l].break_address })
                                contentarr.push({ "key": "City", "value": locations[l].city })
                                contentarr.push({ "key": "State", "value": locations[l].break_state })
                            }
                            else {
                                contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
                                contentarr.push({ "key": "Break End", "value": locations[l].break_end })
                                contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
                                contentarr.push({ "key": "Address", "value": locations[l].break_address })
                                contentarr.push({ "key": "City", "value": locations[l].city })
                                contentarr.push({ "key": "State", "value": locations[l].break_state })
                            }
                        }
                        catch (e) {
                            contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
                            contentarr.push({ "key": "Break End", "value": locations[l].break_end })
                            contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
                            contentarr.push({ "key": "Address", "value": locations[l].break_address })
                            contentarr.push({ "key": "City", "value": locations[l].city })
                            contentarr.push({ "key": "State", "value": locations[l].break_state })
                        }
                        var contentString = infoBox(marker.icon, header, contentarr, '')
                        infowindow.setContent(contentString);
                        currentwindow = infowindow;
                        infowindow.open(map, marker);
                        //console.log(marker.position.toJSON());
                        // console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
                    }
                })(marker, l));
            }
            marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
                icon: require('../../assets/icons/track_start.png'),
                map: map,
            });
            window.google.maps.event.addListener(marker, 'click', (function (marker) {
                return function () {
                    //	console.log(clusters[0])
                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                    var contentarr = []
                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                    var header = "Starting Point"
                    contentarr.push({ "key": "Started at", "value": routeinfo.start_time })
                    var contentString = infoBox(marker.icon, header, contentarr, '')
                    infowindow.setContent(contentString);
                    currentwindow = infowindow;
                    infowindow.open(map, marker);
                    // console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
                }
            })(marker));
            if (this.state.adaniCheck == 1) {
                marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
                    icon: require('../../assets/icons/shuttle-end.png'),
                    map: map,
                });
            }
            else {
                marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
                    icon: require('../../assets/icons/truck-end.png'),
                    map: map,
                });
            }
            window.google.maps.event.addListener(marker, 'click', (function (marker) {
                return function () {
                    //	console.log(clusters[0])
                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
                    var contentarr = []
                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                    var header = "End Point"
                    contentarr.push({ "key": "End at", "value": routeinfo.end_time })
                    var contentString = infoBox(marker.icon, header, contentarr, '')
                    infowindow.setContent(contentString);
                    infowindow.setContent(contentString);
                    currentwindow = infowindow;
                    infowindow.open(map, marker);
                    // console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
                }
            })(marker));
        }
        try {
            if (tolls.length > 0) {
                var infowindow = new window.google.maps.InfoWindow();
                var l;
                tolls.map(function (e, index) {
                    var tollMarker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(e.lat, e.lon),
                        icon: require('../../assets/icons/barrier.png'),
                        map: map,
                    });
                    window.google.maps.event.addListener(tollMarker, 'mouseover', (function (tollMarker, index) {
                        return function () {
                            var contentarr = []
                            var header = "Toll - " + e.name
                            contentarr.push({ "key": "Address", "value": e.location })
                            var contentString = infoBox(tollMarker.icon, header, contentarr)
                            infowindow.setContent(contentString);
                            var currentwindow = infowindow;
                            infowindow.open(map, tollMarker);
                        }
                    })(tollMarker, index));
                    window.google.maps.event.addListener(tollMarker, 'mouseout', function () {
                        infowindow.close();
                    });
                })
            }
        } catch (e) { }
        map.fitBounds(bounds)
    }
    exportPDF() {
        let doc = new pdf.PdfDocument({
            header: {
                declarative: {
                    text: 'Carrier Route\t&[Page]\\&[Pages]',
                    font: new pdf.PdfFont('times', 12),
                    brush: '#bfc1c2'
                }
            },
            lineGap: 2,
            pageSettings: {
                margins: {
                    left: 10,
                    right: 10,
                    top: 10,
                    bottom: 10
                },
                size: "A2"
            },
            pageAdded: function (s, e) {
                let image = require('../../assets/icons/logo.png');
                let enmovilImage = require('../../assets/icons/enmovil.png');
                //
                s.drawImage(image, null, null, {
                    width: 150,
                    height: 30,
                    stretchProportionally: true,
                    align: pdf.PdfImageHorizontalAlign.Center
                });
                s.drawImage(enmovilImage, 1000, 0, {
                    width: 100,
                    height: 30,
                    stretchProportionally: true,
                    align: pdf.PdfImageHorizontalAlign.Right
                });
            },
            ended: (sender, args) => pdf.saveBlob(args.blob, 'Document.pdf')
        });
        var consignees = JSON.parse(JSON.stringify(this.state.rowData))
        // console.log(consignees, "consignees")
        consignees.map(function (c) {
            try {
                if (c.lat != undefined && c.lat != '') {
                    c.lat = c.lat.toFixed(6);
                }
                else {
                    c.lat = "";
                }
            }
            catch (e) {
                c.lat = "";
            }
            try {
                if (c.lng != undefined && c.lng != '') {
                    c.lng = c.lng.toFixed(6);
                }
                else {
                    c.lng = "";
                }
            }
            catch (e) {
                c.lng = "";
            }
            if (c.stime != "") {
                c.stime = getHyphenDDMMMYYYYHHMM(c.stime);
            }
            else {
                c.stime = "";
            }
            if (c.time_from_prev_point != '' && c.time_from_prev_point != undefined) {
                
                c.time_from_prev_point = secondsToDhms(c.time_from_prev_point);
            }
            else {
                return c.time_from_prev_point = secondsToDhms(c.time_from_prev_point);;
                // c.time_from_prev_point = "";
            }
            if (c.cumm_distance != '' && c.cumm_distance != undefined) {
                c.cumm_distance = convertMeters(c.cumm_distance);
            }
            else {
                c.cumm_distance = convertMeters(c.cumm_distance);
            }
        });
        if (consignees.length > 40) {
            var totalPages = (consignees.length / 40).toString();
            // console.log("tpages", totalPages)
            var lastRemainder = parseInt(totalPages.split(".")[1])
            totalPages = parseInt(totalPages.split(".")[0])
            // console.log(totalPages, "totalPages")
            // console.log(lastRemainder, "lastRemainder")
            // var endPage = 75
            // var startPage = 0
            while (consignees.length !== 0) {
                let sen = consignees.splice(0, 40)
                this.$_drawEmployee(doc, sen);
            }
            // for(var i=0;i<totalPages;i++)
            // {
            //     //console.log(startPage, endPage);
            //     var sen = consignees.slice(startPage,endPage);
            //     this.$_drawEmployee(doc, sen);
            //     if((i+1) == totalPages)
            //     {
            //         var sen1 = consignees.slice(endPage+1,lastRemainder);
            //         this.$_drawEmployee(doc, sen1);
            //     }
            //     else
            //     {
            //         startPage = endPage + 1;
            //         endPage = endPage + 75;
            //     }
            // }
        }
        else {
            this.$_drawEmployee(doc, consignees);
        }
        // consignees.forEach((employee, i, arr) => {
        //     this.$_drawEmployee(doc, employee);
        //     if (i < arr.length - 1) {
        //         doc.addPage();
        //     }
        // });
        doc.end();
    }
    //
    $_drawEmployee(doc, consignees) {
        //let tot = employee.expenses.totals;
        let expenses = consignees;
        // console.log(expenses,"expenses")
        if (expenses.length > 0) {
            let colIds = []
            var columnStates =  this.gridColumnApi.getColumnState()
            var columndefs = []
            columnStates.map(e => {
                this.gridColumnApi.columnController.columnDefs.map(f => {
                    if (e.hide == false && e.colId != "0"){
                        if (e.colId== f.field){
                            columndefs.push({header : `${f.headerName}` , binding : `${f.field}`})
                        }
                    }
                })
            })
            
            let columns =columndefs ,bold = new pdf.PdfFont('times', 16, 'normal', 'bold'), colWidth = this.colWidth, rowHeight = this.rowHeight;
            
            let y = doc.y;
            doc.moveDown(2);
            // * draw table *
            doc.saveState();
            y = 0;
            let scale = doc.width / (columns.length * colWidth), docY = doc.y;
            if (scale > 1) {
                scale = 1;
            }
            doc.scale(scale, scale, new wijmo.Point(0, docY));
            doc.translate(0, docY);
            // header
            this.$_renderRow(doc, y, columns, column => column.header, null, bold, '#fad9cd');
            y += rowHeight;
            // body
            expenses.map((item, index) => {
                //this.$_checkLineAvailable(doc);  
                this.$_renderRow(doc, y, columns, column => item[column.binding], column => column.format ,bold = new pdf.PdfFont('times', 16, 'normal'));
                y += rowHeight;
            });
            doc.addPage(doc.currentPageSettings);
            doc.footer.drawText("Report generated on enmovil.io by Enmovil Solutions", null, null, {
                align: pdf.PdfTextHorizontalAlign.Right
            });
        }
    }
    //
    $_checkLineAvailable(doc) {
        if (doc.height - doc.y < doc.lineHeight() + doc.lineGap) {
            doc.addPage();
        }
    }
    //
    $_renderRow(doc, y, values, valueGetter, formatGetter, font, brush) {
        let colWidth = this.colWidth, rowHeight = this.rowHeight;
        //
        values.forEach((v, idx) => {
            let x = idx * colWidth;
            //
            doc.paths
                .rect(x, y, colWidth, rowHeight)
                //.fill(brush || '#eaeaea')
                .stroke();
            //
            let value = valueGetter != null ? valueGetter(v) : v || '';
            let format = formatGetter != null ? formatGetter(v) : '';
            //
            if (value !== 'Total') {
                value = wijmo.changeType(value, wijmo.DataType.String, format);
            }
            //
            doc.drawText(value, x + 2, y + 2, {
                font: font,
                height: rowHeight,
                width: colWidth
            });
        });
        doc.moveDown(5);
    }
    onClickShowTruckLocation(e) {
        // console.log("CLiclable ", e)
        var lat = parseFloat(e.data.lat);
        var lng = parseFloat(e.data.lng);
        var data = e.data;
        map.setCenter(new window.google.maps.LatLng(lat, lng));
        map.setZoom(22);
        var markerLatlng = new window.google.maps.LatLng(lat, lng);
        //var image =  require('../../assets/icons/truckblue.png');
        var image = require('../../assets/icons/gmarker.png');

        var marker = new window.google.maps.Marker({
            position: markerLatlng,
            map: map,
            title: data.truck_no,
            area : data.area,
            icon: image
        });


        var contentarr = []
        var header = data.truck_no
        //contentarr.push({"key":"Reached On", "value":getDDMMYYYYHHMMSS(data.reached_on)})
        contentarr.push({ "key": "City/Area", "value": data.area })
        contentarr.push({ "key": "State", "value": data.state })

        var contentString = infoBox(marker.icon, header, contentarr, '')

        var infowindow = new window.google.maps.InfoWindow({
            content: contentString
        });

        marker.setAnimation(window.google.maps.Animation.DROP)
        marker.addListener('click', function () {
            infowindow.open(map, marker);
        });

        marker.setMap(map);
        // this.setState({
        //     view_carrier_center : {lat:lat , lng:lng}
        // })
//         console.log(data, "data")
//         this.state.nbmap.map.setCenter({lat:lat , lng: lng})
//         this.state.nbmap.map.setZoom(20)
//     var icon = require('../../assets/icons/gmarker.png')
//     const popup = new Popup({ offset: 10, closeButton: false })
//       .setLngLat({ lat: data.lat, lng: data.lng })
//       .setHTML(`<div class="popup-card">
//     <div class="popup-header-wrapper">
//       <div class="popup-header"><img class='mr-3' src="${icon}"> ${data.truck_no}</div>
//     </div>
//     <div class="popup-body">
//       <table class="popup-table">
//         <tr>
//           <td class="popup-key">City/Area  </td>
//           <td class="popup-value">${data.area}</td>
//         </tr>
//         <tr>
//           <td class="popup-key">State  </td>
//           <td class="popup-value">${data.state}</td>
//         </tr>
//         </table>
//     </div>
//   </div>
//   `)
//       .setMaxWidth('300px')
  
//     const el = document.createElement('div')
//     el.className = 'animate-marker'
//     el.style.backgroundImage =
//       `url(${icon})`
//     el.style.backgroundSize = 'cover'
//     el.style.opacity = '1';
// el.style.animation = 'dropMarker 1s';
// el.style.animationFillMode = 'forwards';
//     const marker = new Marker({
//       element: el,
//     })
//       .setLngLat({ lat: data.lat, lng: data.lng})
//       .addTo(this.state.nbmap.map)
//       marker.setPopup(popup);
//     //   marker.getElement().addEventListener('mouseenter', () => {
//         marker.togglePopup();
//     //   });
      
//     //   marker.getElement().addEventListener('mouseleave', () => {
//         marker.togglePopup();
//     //   });
    
        // map.setCenter(new window.google.maps.LatLng(lat, lng));
        // map.setZoom(22);
        // var markerLatlng = new window.google.maps.LatLng(lat, lng);
        //var image =  require('../../assets/icons/truckblue.png');
        // var image = require('../../assets/icons/gmarker.png');

        // var marker = new window.google.maps.Marker({
        //     position: markerLatlng,
        //     map: map,
        //     title: data.truck_no,
        //     icon: image
        // });


        // var contentarr = []
        // var header = data.truck_no
        // //contentarr.push({"key":"Reached On", "value":getDDMMYYYYHHMMSS(data.reached_on)})
        // contentarr.push({ "key": "City/Area", "value": data.area })
        // contentarr.push({ "key": "State", "value": data.state })

        // var contentString = infoBox(marker.icon, header, contentarr, '')

        // var infowindow = new window.google.maps.InfoWindow({
        //     content: contentString
        // });

        // marker.setAnimation(window.google.maps.Animation.DROP)
        // marker.addListener('click', function () {
        //     infowindow.open(map, marker);
        // });

        // marker.setMap(map);  

    }
    showMarkers(event) {
        // console.log("event.target.value ", event.target.value)
        if (event.target.value == 0) {
            this.setState({
                showMarkers: 1
            });
            // viewMarkersArr.map(function(e){
            // 	e.setVisible(true);
            // })
            // console.log("markersArray ", markersArray)
            var infowindow = new window.google.maps.InfoWindow();
            if (markersArray.length > 0) {
                var stepby = Math.round(markersArray.length * 0.007)
                // var counter = ( stepby > 0)?stepby:1;
                // var incrementby = counter
                var counter = 1;
                var incrementby = 1;
                // console.log(markersArray.length, counter);
                while (counter < markersArray.length) {
                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(markersArray[counter].lat, markersArray[counter].lng),
                        icon: {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            strokeColor: '#157254',
                            fillColor: '#157254',
                            fillOpacity: 1,
                            strokeWeight: 5,
                            scale: 1,
                        },
                        map: map,
                        content: markersArray[counter]
                    });
                    // marker.setVisible(false);
                    window.google.maps.event.addListener(marker, 'mouseover', (function (marker) {
                        return function () {
                            // console.log("Arr ",marker)
                            var contentarr = []
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            var header = "Current Status"
                            contentarr.push({ "key": "Time ", "value": getDDMMYYYYHHMMSS(marker.content.stime) })
                            contentarr.push({ "key": "Speed ", "value": marker.content.speed + " KMPH" })
                            contentarr.push({ "key": "Address ", "value": marker.content.area })
                            contentarr.push({ "key": "City ", "value": marker.content.city })
                            contentarr.push({ "key": "State ", "value": marker.content.state })

                            var contentString = infoBox("", header, contentarr, '')


                            infowindow.setContent(contentString);
                            infowindow.open(map, marker);
                            //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                        }
                    })(marker));
                    window.google.maps.event.addListener(marker, 'mouseout', function () {
                        infowindow.close();
                    });

                    viewMarkersArr.push(marker);
                    counter = counter + incrementby;
                    // console.log("counter",counter)
                }
                // for(var a=0;a<markersArray.length;a++)
                // {

                // }
            }

        }
        else {
            this.setState({
                showMarkers: 0
            });
            viewMarkersArr.map(function (e) {
                e.setVisible(false);
            });
        }
    }
    
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        this.restoreGridStates()
    };


    onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();

		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.pageTitle
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:this.state.pageTitle
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				console.log("gridcolumns",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}


	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.pageTitle
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

    getGoogleMaps = async () => {
       await this.setState({
            for_google_maps : true,
            done_with_google_maps:false
        })
        this.formHandler()

    }    

    render() {
        let order_cnt = [];

        const dStyles = {
            width: '100%',
            height: '100%'
        }
        const modalStyles = {
            width: '500px !important',
        }
        const { open } = this.state;
        var adaniCheck = this.state.adaniCheck;
        var tkName = "Truck No";
        if (adaniCheck == 1) {
            tkName = "Shuttle No";
        }
        if (adaniCheck == 1) {
            tkName = "Shuttle No";
        }
        if(localStorage.getItem("transportercode").indexOf("JWS01") >= 0)
        {
            tkName = "Device ID"
        }
        const columnwithDefs = [
            {
                headerName: "",
                field: "",
                width: 50,
                //   cellRenderer:'trucklocaction'
                cellRendererSelector: function (params) {
                    if (params.data._id != "total") {
                        return {
                            component: "trucklocaction"
                        }
                    }
                },
            },
            {
                headerName: tkName,
                field: "truck_no",
                width: 100,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (localStorage.getItem("transportercode").indexOf("JWS01") >= 0) {
                        return "Test Rake";
                    }
                    else {
                        return params.data.truck_no;
                    }

                }

            },
            {
                headerName: "Latitude",
                field: "lat",
                width: 120,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        return params.data.lat.toFixed(6);
                    }
                    catch (e) {
                        return params.data.lat;
                    }

                }

            },
            {
                headerName: "Longitude",
                field: "lng",
                width: 120,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        return params.data.lng.toFixed(6);
                    }
                    catch (e) {
                        return params.data.lng;
                    }

                }

            },
            //   {
            //       headerName: "Last Known State",
            //       field: "state",
            //       width: 140,
            //       filter: true,
            //       resizable: true

            //   },
            //   {
            //       headerName: "Last Know City",
            //       field: "city",
            //       width: 140,
            //       filter: true,
            //       resizable: true

            //   },

            //   {
            //     headerName: "Last Know Area",
            //     field: "area",
            //     width: 140,
            //     filter: true,
            //     resizable: true

            // },
            //   {
            //       headerName: "Location Type",
            //       field: "location_type",
            //       width: 140,
            //       filter: true,
            //       resizable: true

            //   },
            {
                headerName: "Area / City",
                field: "area",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Geofence Name",
                field: "geofence_name",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "District",
                field: "district",
                width: 150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "State",
                field: "state",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Speed (Kms)",
                field: "speed",
                width: 80,
                filter: true,
                resizable: true

            },
            {
                headerName: "Packet Time",
                field: "stime",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    //console.log(params);
                    if (params.data.stime != "") {
                        return getHyphenDDMMMYYYYHHMM(params.data.stime);
                    }
                    else {
                        return "";
                    }

                },
                // valueGetter:function(params){
                // 	console.log(params);
                // 	return params.data.stime;
                // }
                //   // filter: "agDateColumnFilter",
                comparator: dateComparator,

            },
            //   {
            //         headerName: "Received On",
            //         field: "received_on",
            //         width: 140,
            //         filter: true,
            //         resizable: true,
            //         valueGetter:function(params){
            //             console.log(params.data.received_on);
            //             if(params.data.received_on != "NaT")
            //             {
            //                 return getHyphenDDMMMYYYYHHMM(params.data.received_on);
            //             }
            //             else{
            //                 return "";
            //             }

            //         },
            //         // // filter: "agDateColumnFilter",
            // 		comparator: dateComparator,


            //   },
            // {
            //     headerName: "Time from previous point",
            //     field: "time_from_prev_point",
            //     width: 240,
            //     filter: true,
            //     resizable: true,
            //     valueGetter: function (params) {
            //         if (params.data.time_from_prev_point != 'Total') {
            //             return secondsToDhms(params.data.time_from_prev_point);
            //         }
            //         else {
            //             return "Total";
            //         }

            //     }

            // },
            //   {
            //       headerName: "Distance from previous point (KM)",
            //       field: "dist_from_prev_point",
            //       width: 240,
            //       filter: true,
            //       resizable: true,
            //       valueGetter : function(params)
            //       {
            //         return  convertMeters(params.data.dist_from_prev_point);
            //       }

            //   },
            // {
            //     headerName: "Cummulative Distance (KM)",
            //     field: "cumm_distance",
            //     width: 140,
            //     filter: true,
            //     resizable: true,
            //     valueGetter: function (params) {
            //         return convertMeters(params.data.cumm_distance);
            //     }

            // },
        ]
        if(localStorage.getItem('email')=='gbfc@enmovil.in' ){
            columnwithDefs.push(
                {
                    headerName: "Near Dealer Name",
                    field: "near_dealer_name",
                    width: 80,
                    filter: true,
                    resizable: true
                    
                },
                {
                    headerName: "Near Dealer Distance (in KM)",
                    field: "near_dealer_distance",
                    width: 80,
                    filter: true,
                    resizable: true
                    
                }
            )
          }
        if(localStorage.getItem("transportercode").indexOf("JWS01") >= 0)
        {
            columnwithDefs.push(
                {
                headerName: "Battery",
                field: "battery",
                width: 140,
                filter: true,
                resizable: true,
            })
        }
        else{
            columnwithDefs.push(
                {
                headerName: "Time from previous point",
                field: "time_from_prev_point",
                width: 240,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.time_from_prev_point != 'Total') {
                        return secondsToDhms(params.data.time_from_prev_point);
                    }
                    else {
                        return "Total";
                    }

                }

            },
            {
                headerName: "Cummulative Distance (KM)",
                field: "cumm_distance",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    return convertMeters(params.data.cumm_distance);
                }

            })
        }
        let columnwithtrucksDefs = [
            {
                headerName: `FNR Number`,
                field: "rr_number",
                width: 120,
                editable: false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: `Status`,
                field: "last_destination_report",
                width: 120,
                editable: false,
                filter: "agSetColumnFilter",
            },
    
        ]
        var listData = []
        this.state.filteredData.map(function (e) {
            listData.push(<li key={e} className="tptdropdown-truck-no">{e}</li>)
        })
        return (
            <div >
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                {/*Container-fluid starts*/}
                <div className="container-fluid">
                    <div className="row col-xl-12 col-lg-12">
                        <div className={"trucks-dropdown " + this.state.showTrucksList}>
                            <ul>
                                {listData}
                            </ul>
                        </div>
                        <form className="row theme-form col-xl-10 col-lg-10" onSubmit={this.formHandler}>
                            <div className="col-xl-2 col-lg-2 form-group">
                                {/*<select name="truck_no" className="form-control" onChange={this.handlerForm.bind(this)}>
	                    			<option value="">Truck No</option>
	                    			{this.selectOptionsItems()}
	                    		</select>
								*/}
                                {(localStorage.getItem("transportercode").indexOf("JWS01") >= 0)?
                                <input
                                    type="text"
                                    name="truck_no"
                                    id="inputTruck"
                                    // placeholder={(this.state.adaniCheck == 1) ? "Shuttle" : "Truck" + " No"}
                                    autoComplete="off"
                                    value = "Test Rake"
                                    className="form-control"
                                    // onFocus={this.initalList.bind(this)}
                                    // onChange={this.handlerForm.bind(this)}
                                     />
                                     :
                                <input
                                    type="text"
                                    name="truck_no"
                                    id="inputTruck"
                                    placeholder={(this.state.adaniCheck == 1) ? "Shuttle" : "Truck" + " No"}
                                    autoComplete="off"
                                    className="form-control"
                                    onFocus={this.initalList.bind(this)}
                                    onChange={this.handlerForm.bind(this)} />}
                            </div>
                            <div className="col-xl-3 col-lg-3 form-group">
                                <Datetime
                                    inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete: 'off' }}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat="HH:mm:ss"
                                    value = {this.state.startDate}
                                    name="startDate"
                                    onChange={this.handlerStartDateTime.bind(this)} />
                            </div>

                            <div className="col-xl-3 col-lg-3 form-group">
                                <Datetime
                                    inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete: 'off' }}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat="HH:mm:ss"
                                    value ={this.state.endDate} 
                                    name = "endDate"
                                    onChange={this.handlerEndDateTime.bind(this)} />
                            </div>
                            <div className="col-xl-2 col-lg-2 form-group">
                                <input type="number" name="frequency" id="frequency" value={this.state.frequency} placeholder="Data Interval (Mins)" className="validateNumber form-control" onChange={this.checkHandler.bind(this)} />
                            </div>
                            <div className="col-xl-1 col-lg-1 form-group">
                                <button type="submit" className="btn btn-success">Generate</button>
                            </div>
                        </form>
                        {(localStorage.getItem("transportercode").indexOf("JWS01") >= 0)?
                        <div className="form-group col-xl-2 col-lg-2">
                                <button className="floatright btn btn-info" onClick={this.onClickFnrDetails.bind(this)}>
                                    Track FNR</button>
                        </div>:""}
                    </div>
                    {(this.state.rowData != null) ?
                        <div className="row col-xl-12 col-lg-12">
                            <div className="col-xl-12 col-lg-12">
                            {/* <button className="btn btn-success" style={{ float: "right", marginRight: "10px" }} onClick={this.getGoogleMaps.bind(this)} >Google Maps</button> */}
                                <button className="btn btn-danger" style={{ float: "right", marginRight: "10px" }} onClick={this.exportPDF.bind(this)}>Export To PDF</button>
                                <button className="btn btn-primary" style={{ float: "right", marginRight: "10px" }} onClick={this.resetState.bind(this)}>Reset Default Layout</button>
                                <button className="btn btn-info" style={{ float: "right", marginRight: "10px" }} onClick={this.onClickSaveGridState.bind(this)} >Save Grid Layout</button>

                            </div>
                            {/* <button type="button" className={"btn "+(this.state.actgridbtn)} onClick={this.onClickShowGrid.bind(this)}>Grid</button>
                            <button type="button" className={"btn "+(this.state.actmapbtn)} onClick={this.onClickShowMap.bind(this)}>Map</button> */}
                            <div className="col-xl-6 col-lg-6">
                                <div id="myGrid" style={{ height: "580px", width: "100%", marginTop: '10px' }} className={"ag-theme-balham " + (this.state.gridshow)}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        pinnedBottomRowData={this.state.tottalDistance}
                                        gridOptions={{
                                            context: { componentParent: this },
                                            getRowStyle: function (params) {
                                                if (params.node.rowPinned) {
                                                    return { 'font-weight': 'bold', 'font-size': '16px' };
                                                }
                                            },
                                        }}
                                    />


                                </div>
                                {(localStorage.getItem("transportercode").indexOf("JWS01") >= 0)?"" :
                                <div className="card-header">
                                    <h6>
                                        <i className="fa fa-info-circle" style={{color:"cornflowerblue"}}></i> <span> Note : Value is being displayed as 0 because difference between packets is less than 60 secs </span>
                                        
                                    </h6>
				   			    </div>}
                            </div>
                            {/* {(this.state.mapinfoclick != '')?this.state.mapshow */}
                            <div className={"col-xl-6 col-lg-6 "} style={{ padding: "10px" }}>
                                {/* {this.state.for_google_maps? */}
                                <div>
                                    <input type="checkbox" name="viewtimelinemarkers" value={this.state.showMarkers} onClick={this.showMarkers.bind(this)} /> <span style={{ fontSize: "14px" }}>View Markers</span></div>
                                    {/* :"" */}

                                {/* } */}

                                {/* <DrawMap 
                                    context={this} 
                                    mapFor={"truckgps"} 
                                    tolls = {this.state.tolls}
                                    mapinfo={this.state.mapinfo} 
                                    markersArray = {markersArray}
                                    defTransitCoords={""} /> */}

                                {/* {!this.state.for_google_maps?
                                this.state.shownbmaps?
                                 <NextBillionMaps 
                                 context={this} 
                                 mapFor={"truckgps"} 
                                 tolls = {this.state.tolls}
                                 mapinfo={this.state.mapinfo} 
                                 markersArray = {markersArray}
                                 view_carrier_center = {this.state.view_carrier_center}
                                />
                                        
                                :""
                                : */}
                                <div id="map" className="" style={{ width: '100%', height: "70vh" }}></div>
                                {/* } */}
                                <div class="card">
                                    <div class="card-header" style={{ background: "green", color: "white" }}>View Details</div>
                                    <div class="card-body" style={{ padding: "15px" }}>
                                        <div className="row">
                                            <div className="col-sm-3 col-xl-3 col-lg-3 col-md-3" style={{ borderRight: "1px solid #e4e4e4", textAlign: 'center' }}><b>Truck No</b> <br /><span>{this.state.inputTruck}</span></div>
                                            <div className="col-sm-3 col-xl-3 col-lg-3 col-md-3" style={{ borderRight: "1px solid #e4e4e4", textAlign: 'center' }}><b>Start Date</b><br /><span>{getHyphenYYYYMMDDHHMMSS(this.state.startDate)}</span></div>
                                            <div className="col-sm-3 col-xl-3 col-lg-3 col-md-3" style={{ borderRight: "1px solid #e4e4e4", textAlign: 'center' }}><b>End Date</b><br /><span>{getHyphenYYYYMMDDHHMMSS(this.state.endDate)}</span></div>
                                            <div className="col-sm-3 col-xl-3 col-lg-3 col-md-3" style={{ borderRight: "1px solid #e4e4e4", textAlign: 'center' }}><b>Total Distance</b><br /><span>{parseFloat((this.state.totaldistanceformap[0]).cumm_distance / 1000).toFixed(2)}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* :""} */}
                        </div>

                        : ""}

                    <div className={"dataLoadpage " + (this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                    </div>
                </div>
                {/*Container-fluid Ends*/}

                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <div className={"slide-r " + this.state.sliderTranslate}>
                    <div className="slide-r-title">
                        <h4>
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        {/* <button className="float-right export-btn white btn-danger" onClick={() => this.onBtExport()} 
                        style={{marginRight:"1em", padding:"6px"}}> Export to Excel</button> */}
                       </h4>
                       
                    </div>
                    
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        {/* {this.state.trucks_without_driver} */}
                                <div
                                    id="myGrid"
                                    style={{ height: "450px", width: "100%" }}
                                    className="ag-theme-balham"
                                    >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithtrucksDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.fnrRowData}
                                        enableCharts={false}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady1}
                                        onGridState={this.onGridState1}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                    /> 
                                </div>
                        {/* {(this.state.trucks_without_driver.map(function(e){
                            return <div className="col-xl-12 col-lg-12">
                                <h6>{e}</h6>
                            </div>
                        }))} */}
                    </div>
                </div>
            </div>
        );
    }
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
$(document).ready(function () {
    $(document).on("click", ".tptdropdown-truck-no", function () {
        var vals = $(this).text();
        $("#inputTruck").val(vals);
        $(".trucks-dropdown").removeClass("show-m");
        // $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#inputTruck");
        if (!container.is(e.target)) {
            try {
                if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
                    $(".trucks-dropdown").removeClass("show-m");
                    // $(".trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }

        }


    })
})

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function initOMS() {
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
        //markersWontMove: true,
        //keepSpiderfied: true,
        //nearbyDistance: 10,
        //circleFootSeparation: 60,
        //legWeight: 1.5
        markersWontMove: true,
        markersWontHide: true,
        keepSpiderfied: true,
        basicFormatEvents: true
    });
}
function timeConvert(n) {
    var num = n;
    var hours = (num / (3600));
    var rhours = parseInt(hours);
    var minutes = (num - (rhours * 3600)) / (60);
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}

function ConvertMinutes(num) {
    var d = Math.floor(num / 1440); // 60*24
    var h = Math.floor((num - (d * 1440)) / 60);
    var m = Math.round(num % 60);
    return d + " Day(s) " + h + " Hrs " + m + " mins"
}

function ConvertSeconds(totalSeconds) {
    // var days = Math.floor(num/86400);
    // var hours = Math.floor(num/3600)
    // var minutes = Math.floor(num/60);
    var minutes = Math.round((totalSeconds % 3600) / 60);
    var hours = Math.round((totalSeconds % 86400) / 3600);
    var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
    // let d = (new Date(t0)) - (new Date(t1));
    // let weekdays     = Math.floor(d/1000/60/60/24/7);
    // let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
    // let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
    // let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
    return days + " Day(s) " + hours + " Hrs " + minutes + " mins"
}

function ConvertHHMMSeconds(n) {
    var days = Math.round(parseInt(n / (24 * 3600)));

    n = n % (24 * 3600);
    var hours = Math.round(parseInt(n / 3600));

    n %= 3600;
    var minutes = Math.round(n / 60);

    n %= 60;
    var seconds = Math.round(n);

    return days + " Day(s) " + hours + " Hrs " + minutes + " mins " + seconds + " secs"
}
function convertMeters(metersValue) {
    return (metersValue / 1000).toFixed(2);
}

$(document).ready(function () {
    $(document).on('keyup', ".validateNumber", function () {
        // alert("click");
        if (isNaN($(this).val()) == true) {
            $(this).val($(this).val().slice(0, -1));
        }
    });
});
function arrayMax(arr) {
    return arr.reduce(function (p, v) {
        return (p > v ? p : v);
    });
}